<!--
 * @Description: 
 * @Date: 2021-05-06 15:22:34
 * @LastEditors: tonglin.li
 * @LastEditTime: 2021-05-10 18:07:43
 * @FilePath: \ztesa-zsnc-admin\src\components\FormView\FormDropDownInput\index.vue
-->
<script>
import { Dropdown, Menu, Input, Button } from 'ant-design-vue'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    value: {
      type: String,
      default: () => {}
    },
    typeData: {
      type: Array,
      default: () => []
    },
    setValue: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      visible: false,
      inputText: this.value
    }
  },
  watch: {
    visible(a, b) {
      this.item.onInputSearch(this.value)
    }
  },
  destroyed() {
    this.visible = false
  },
  beforeDestroy() {
    this.visible = false
  },
  methods: {
    async onInput(data) {
      const value = data.target.value
      this.$emit('change', value)
      if (!this.visible) {
        this.visible = true
      }

      if (this.item.onInputSearch) {
        this.item.onInputSearch(value)
      }
      this.inputText = value
    },
    onMenuClick(data) {
      if (this.item.onMenuClick) {
        this.item.onMenuClick(data)
      }
      this.$emit('change', data.name)
      this.setValue && this.setValue(this.item.valueKey, data.value)
      this.visible = false
    },
    renderInputSearch() {
      return (
        <Input.Search
          props={this.item.inputProps}
          onInput={this.onInput}
          value={this.value}
        />
      )
    },
    renderInput() {
      return (
        <Input
          props={this.item.inputProps}
          onInput={this.onInput}
          value={this.value}
          // v-model={this.item.valueKey}
        />
      )
    },
    renderMenu() {
      return (
        <Menu>
          {this.typeData.slice(0, 10).map(e => {
            return (
              <Menu.Item key={e.value} onClick={() => this.onMenuClick(e)}>
                {e.name}
              </Menu.Item>
            )
          })}
        </Menu>
      )
    },
    setShow(state) {
      this.visible = state
    }
  },
  render() {
    return (
      <div style={{ marginTop: '4px' }}>
        <Dropdown
          visible={this.visible}
          style={{ marginBottom: '-10px', height: '100%' }}
        >
          <div class="drop-down-main">
            {this.item.inputProps.type == 'InputSearch'
              ? this.renderInputSearch()
              : this.renderInput()}
            <Button
              icon={'menu'}
              type={'primary'}
              onClick={() => (this.visible = !this.visible)}
            />
          </div>
          <template slot="overlay">
            {this.item.renderOverlay
              ? this.item.renderOverlay({ setShow: this.setShow })
              : this.renderMenu()}
          </template>
        </Dropdown>
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.drop-down-main {
  display: flex;
  align-items: center;
  width: 100%;
  [type='button'] {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-bottom: 2px;
    margin-left: -1px;
    width: 50px;
  }
}
</style>
