<script>
import FormHeader from '../FormHeader'
import TableView from '../TableVIew'
import XLSX from 'xlsx'
import {
  Button,
  Tooltip,
  Icon,
  Dropdown,
  Menu,
  Card,
  Checkbox,
  message,
  Pagination
} from 'ant-design-vue'
import api from '@/command/api'
import apiTool from '@/command/apiTool'
export default {
  props: [
    'headerData',
    'button',
    'tableColumns',
    'headerTypeData',
    'records',
    'isShowCard',
    'isShowPage',
    'total',
    'onSelectRow',
    'rightButton',
    'tableProps',
    'showRowSelect',
    'filterRightButtonKey',
    'currentRoute'
  ],
  data() {
    return {
      checkeds: new Set([]),
      tableSize: 'middle',
      isFull: false,
      visible: false
    }
  },
  mounted() {
    // 初始化列选择
    this.initCheck()
  },
  methods: {
    initCheck() {
      this.checkeds = new Set(
        (this.tableColumns || [])
          .filter(e => e.dataIndex !== 'id' && !e.isId)
          .map(e => e.dataIndex)
      )
    },
    //   菜单点击事件
    onMenuClick(menu) {
      this.tableSize = menu.key
    },
    renderNormal(e) {
      return (
        <Button
          icon={e.icon}
          onClick={e.onClick}
          style={{ marginLeft: '10px' }}
        >
          {e.name}
        </Button>
      )
    },
    renderNormal2(e) {
      return (
        <Button onClick={e.onClick} style={{ marginLeft: '10px' }}>
          <i class="iconfont icon-xiazai"></i>
          {e.name}
        </Button>
      )
    },
    // 下拉菜单点击事件
    onDropClick() {
      this.visible = !this.visible
    },
    renderPopup(e) {
      let that = this
      const onChange = item => {
        if (that.checkeds.has(item.value)) {
          that.checkeds.delete(item.value)
        } else {
          that.checkeds.add(item.value)
        }
        that.$forceUpdate()
      }
      const cardView = function cardView() {
        return (
          <div style={{ width: '150px' }}>
            <Card title="列展示" size="small" headStyle={{ color: '#333333' }}>
              <a slot="extra" href="#" onClick={that.initCheck}>
                重置
              </a>
              <div>
                {e.typeData.map(e => {
                  return (
                    <div class={'header-check-item'}>
                      <Checkbox
                        checked={that.checkeds.has(e.value)}
                        onChange={() => onChange(e)}
                      >
                        {e.name}
                      </Checkbox>
                    </div>
                  )
                })}
              </div>
            </Card>
          </div>
        )
      }
      return (
        <Dropdown visible={this.visible}>
          <Button
            // icon={e.icon}
            onClick={this.onDropClick}
            style={{ marginLeft: '10px' }}
          >
            <i class="iconfont icon-canshupeizhi"></i>
            {e.name}
          </Button>
          <template slot="overlay">{cardView()}</template>
        </Dropdown>
      )
    },
    renderMenu(e) {
      return (
        <Dropdown>
          <Tooltip title={e.name} style={{ marginLeft: '10px' }}>
            <Icon type={e.icon} style={{ fontSize: '15px' }} />
          </Tooltip>
          <template slot="overlay">
            <Menu style={{ width: '80px' }} selected-keys={[this.tableSize]}>
              {(e.menu || []).map(el => {
                return (
                  <Menu.Item key={el.key} onClick={() => e.onClick(el)}>
                    {el.name}
                  </Menu.Item>
                )
              })}
            </Menu>
          </template>
        </Dropdown>
      )
    },
    getDropButtonDown(parent, item) {
      if (parent.isBatch) {
        if (this.$refs.table.selectedRowKeys.length == 0) {
          message.error('请先选择要修改数据')
        } else {
          if (item.onClick) {
            const ret = item.onClick(this.$refs.table.selectedRows)
            if (ret) {
              ret.then(() => (this.$refs.table.selectedRowKeys = []))
            }
          }
        }
      } else {
        item.onClick && item.onClick()
      }
    },
    renderDropDownMenu(item) {
      if (
        item.viewType !== 'menu' ||
        (Array.isArray(item.children) && item.children.length == 0)
      ) {
        return null
      }
      return (
        <template slot="overlay">
          <Menu>
            {item.children.map((e, i) => {
              return (
                <Menu.Item
                  key={i}
                  onClick={() => this.getDropButtonDown(item, e)}
                >
                  {e.name}
                </Menu.Item>
              )
            })}
          </Menu>
        </template>
      )
    },
    renderLeftButton(e) {
      const buttonStyle = {
        borderColor: 'rgba(232, 234, 236, 1)',
        color: '#515A6E'
      }
      return (
        <Dropdown>
          {this.renderDropDownMenu(e)}
          <Button
            icon={e.icon}
            type={e.type}
            ghost={true}
            onClick={() => e.onClick && e.onClick()}
            style={{
              marginRight: '10px',
              ...(e.viewType == 'menu' && buttonStyle),
              ...e.style
            }}
          >
            {e.name}
            {e.viewType == 'menu' && <Icon type="down" />}
          </Button>
        </Dropdown>
      )
    },
    getTableSelect() {
      return this.$refs.table ? this.$refs.table.selectedRows : []
    },
    getBase64Image(image, ext) {
      let canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      let context = canvas.getContext('2d')
      context.drawImage(image, 0, 0, image.width, image.height)
      // 这里是不支持跨域的
      let base64 = canvas.toDataURL('image/' + ext)
      return base64
    },
    onExportXLSX() {
      const selectTable = this.getTableSelect()
      if (selectTable.length == 0) {
        this.$message.error('请先选择要导出项')
        return
      }

      const data = selectTable.map(e => {
        const obj = {}
        this.filterColumn().forEach(el => {
          if (e[el.dataIndex] !== undefined) {
            if (el.onExport) {
              obj[el.title] = el.onExport(e[el.dataIndex], e)
            } else {
              obj[el.title] = e[el.dataIndex]
            }
          }
        })
        return obj
      })
      // 新建空workbook，然后加入worksheet
      const ws = XLSX.utils.json_to_sheet(data)
      // 设置每列的列宽，10代表10个字符，注意中文占2个字符
      ws['!cols'] = [{ wch: 10 }, { wch: 30 }, { wch: 25 }]
      // 新建book
      const wb = XLSX.utils.book_new()
      // 生成xlsx文件(book,sheet数据,sheet命名)
      XLSX.utils.book_append_sheet(wb, ws, '数据详情')
      // 写文件(book,xlsx文件名称)
      XLSX.writeFile(wb, '列表详情.xlsx')
    },
    renderRightButton() {
      const selectTable = this.getTableSelect()
      let rightData = [
        {
          name: `导出Excel(${selectTable.length}条)`,
          icon: 'cloud-download',
          type: 'normal2',
          key: 'export',
          onClick: this.onExportXLSX
        },
        {
          name: '刷新',
          icon: 'reload',
          type: 'normal',
          key: 'refresh',
          onClick: () => {
            api.command.getList.call(this.$parent)
          }
        },
        {
          name: '列设置',
          icon: 'filter',
          type: 'popup',
          key: 'setting',
          typeData: this.tableColumns.map(e => {
            return {
              name: e.title,
              value: e.dataIndex
            }
          })
        }
      ]
      if (this.rightButton && Array.isArray(this.rightButton)) {
        rightData.unshift(...this.rightButton)
      }
      if (this.filterRightButtonKey) {
        rightData = rightData.filter(
          e => this.filterRightButtonKey.indexOf(e.key) !== -1
        )
      }
      return rightData.map(e => {
        switch (e.type) {
          case 'normal':
            return this.renderNormal(e)
          case 'normal2':
            return this.renderNormal2(e)
          case 'menu':
            return this.renderMenu(e)
          case 'popup':
            return this.renderPopup(e)
          default:
            return this.renderNormal(e)
        }
      })
    },
    // 筛选按钮
    filterButton(button) {
      const buttonName = apiTool.getButtonName()
      return button.filter(e => {
        if (e.viewType == 'menu') {
          const mChildren = e.children.filter(el =>
            buttonName.some(ea => el.name.indexOf(ea) !== -1)
          )
          e.children = mChildren
          if (mChildren.length > 0) {
            return true
          } else {
            return false
          }
        }
        return buttonName.indexOf(e.name) != -1
      })
    },
    renderButton() {
      if (!this.button) return null
      //* ************************************** */
      //   const left = this.filterButton(this.button).map((e, i) =>
      //     this.renderLeftButton(e, i)
      //   )
      const left = this.button.map((e, i) => this.renderLeftButton(e, i))
      const right = this.renderRightButton()
      return (
        <div class="header-button">
          <span>{left}</span>
          <span>{right}</span>
        </div>
      )
    },
    filterColumn() {
      return this.tableColumns.filter(e => this.checkeds.has(e.dataIndex))
    },
    renderTable() {
      return (
        <TableView
          ref={'table'}
          size={this.tableSize}
          loading={this.$parent.loading}
          styles={{ marginTop: '20px' }}
          columns={this.filterColumn()}
          dataSource={this.records}
          onSelectRow={this.$listeners.selectRow}
          props={this.tableProps}
          showRowSelect={this.showRowSelect}
          showPagination={false}
        />
      )
    },
    renderHeader() {
      return (
        <FormHeader
          data={this.headerData}
          onSuccess={data => {
            api.command.getList.call(this.$parent, {
              current: 1,
              params: {
                ...data,
                ...this.$parent.paramsValue
              }
            })
          }}
          onReset={() => {
            api.command.getList.call(this.$parent, {
              current: 1,
              params: {
                ...this.$parent.paramsValue
              }
            })
          }}
          typeData={this.headerTypeData}
        />
      )
    },
    renderPagination() {
      return (
        <div class="template-pagination">
          <Pagination
            total={this.$parent.total}
            current={this.$parent.current}
            showSizeChanger={true}
            onShowSizeChange={(data, pageSize) => {
              api.command.getList.call(this.$parent, {
                current: 1,
                pageSize
              })
            }}
            onChange={(data, pageSize) => {
              if (this.currentRoute == 'mainPage') {
                sessionStorage.setItem('pageCurrent', data)
              }

              api.command.getList.call(this.$parent, {
                current: data,
                pageSize
              })
            }}
          />
        </div>
      )
    },
    renderBody() {
      return (
        <div>
          {this.renderHeader()}
          {this.renderButton()}
          {this.renderTable()}
        </div>
      )
    }
  },
  render() {
    if (this.isShowCard != undefined && !this.isShowCard) {
      return this.renderBody()
    }
    return (
      <div class="template-card">
        <Card bordered={false}>{this.renderBody()}</Card>
        {this.renderPagination()}
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-btn {
  border-radius: 5px;
}

/deep/.ant-tag {
  margin-top: 0px !important;
}

.template-card {
  border: 1px solid rgba(232, 234, 236, 1);
  border-radius: 4px;
  min-height: calc(100vh - 128px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  padding-bottom: 20px;
}
.icon-canshupeizhi {
  font-size: 12px;
  margin-right: 8px;
  color: #000;
  font-weight: 900;
  transition: color 0.45s;
}
.icon-xiazai {
  font-size: 14px;
  margin-right: 8px;
  color: #000;
  transition: color 0.45s;
}
/deep/.ant-btn:hover .icon-xiazai {
  color: #40a9ff;
}
/deep/.ant-btn:hover .icon-canshupeizhi {
  color: #40a9ff;
}
.table-main {
  display: flex;
  flex: 1;
  background: red;
}
.header-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  i {
    cursor: pointer !important;
  }
  span {
    display: flex;
    align-items: center;
  }
}

.header-check-item {
  display: flex;
  align-items: center;
  min-height: 30px;
  justify-content: flex-start;
}

/deep/.anticon.anticon-plus {
  font-size: 12px;
}

/deep/.ant-btn {
  line-height: 1;
}
.template-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.template-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
