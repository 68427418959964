<template>
  <a-list
    size="large"
    row-key="id"
    :loading="loading"
    item-layout="vertical"
    :data-source="data"
  >
    <a-list-item :key="item.id" slot="renderItem" slot-scope="item">
      <template slot="actions">
        <icon-text type="star-o" :text="item.star" />
        <icon-text type="like-o" :text="item.like" />
        <icon-text type="message" :text="item.message" />
      </template>
      <a-list-item-meta>
        <a slot="title" href="https://vue.ant.design/">{{ item.title }}</a>
        <template slot="description">
          <span>
            <a-tag>Ant Design</a-tag>
            <a-tag>设计语言</a-tag>
            <a-tag>蚂蚁金服</a-tag>
          </span>
        </template>
      </a-list-item-meta>
      <article-list-content
        :description="item.description"
        :owner="item.owner"
        :avatar="item.avatar"
        :href="item.href"
        :update-at="item.updatedAt"
      />
    </a-list-item>
    <div
      v-if="data.length > 0"
      slot="footer"
      style="text-align: center; margin-top: 16px;"
    >
      <a-button :loading="loadingMore" @click="loadMore">
        加载更多
      </a-button>
    </div>
  </a-list>
</template>

<script>
import { ArticleListContent } from '@/components'
// import IconText from '@/views/list/search/components/IconText'

export default {
  name: 'article',
  components: {
    // IconText,
    ArticleListContent
  },
  data() {
    return {
      loading: true,
      loadingMore: false,
      data: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.$http.get('/list/article').then(res => {
        console.log('res', res)
        this.data = res.result
        this.loading = false
      })
    },
    loadMore() {
      this.loadingMore = true
      this.$http
        .get('/list/article')
        .then(res => {
          this.data = this.data.concat(res.result)
        })
        .finally(() => {
          this.loadingMore = false
        })
    }
  }
}
</script>

<style scoped></style>
