<script>
import { Button, Input } from 'ant-design-vue'
export default {
  props: {
    value: {
      type: String,
      default: '1'
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    this.$emit('change', '1')
  },
  methods: {
    //  增加
    onIncrease() {
      this.$emit('change', Number(this.value) + 1)
    },
    // 减少
    onReduce() {
      if (Number(this.value) > 0) {
        this.$emit('change', Number(this.value) - 1)
      }
    }
  },
  render() {
    return (
      <div class="form-stepper">
        <Button class="stepper-button" onClick={this.onReduce}>
          -
        </Button>
        <Input
          style={{ width: '40px', textAlign: 'center' }}
          value={this.value}
        />
        <Button class="stepper-button" onClick={this.onIncrease}>
          +
        </Button>
        {this.item.desc && <span domProps={{ innerHTML: this.item.desc }} />}
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.form-stepper {
  display: flex;
  align-items: center;
  span {
    font-size: 10px;
    margin-left: 10px;
    line-height: 20px;
  }
}
.stepper-button {
  background: rgba(247, 247, 248);
  &:nth-child(1) {
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &:nth-child(3) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
</style>
