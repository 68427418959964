<script>
import { Cascader } from 'ant-design-vue'
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => {}
    },
    typeData: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    change(value) {
      console.log(value, '== value ==')
      if (this.item.onChange) {
        this.item.onChange(value)
      }
      this.$emit('change', value)
    }
  },
  render() {
    return (
      <Cascader
        onChange={value => this.change(value)}
        value={this.value}
        options={this.typeData}
        placeholder={
          this.item.placeholder
            ? this.item.placeholder
            : '请选择' + (this.item.name || this.item.label)
        }
        // style={{ width: '100%' }}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.form-cascader {
  width: 100%;
}
</style>
