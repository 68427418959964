<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import PushInfo from './comp/PushInfo'
import { Button, Tabs } from 'ant-design-vue'
import GoodsShelves from './comp/GoodsShelves'
import RealTime from './comp/RealTime'
import NetworkMonito from './comp/NetworkMonito'
import { getAction, postAction } from '@/command/netTool'
import moment from 'moment'
export default {
  data() {
    return {
      ...api.command.getState(),
      dataObj: {
        clickNum: 0,
        onlineNum: 0,
        userClickNum: 0,
        userPayAmount: 0,
        userPayNum: 0,
        viewNum: 0
      },
      liveRoomInfo: {
        name: null
      },
      activeKey: '1',
      times: null,
      times2: null,
      liveCountdownText: '',
      id: ''
    }
  },
  mounted() {
    const { id } = this.$route.query
    this.id = id
    getAction('/liveRoomStatistics', { roomId: id }, '/api').then(res => {
      this.dataObj = res.data
    })
    this.getDataInit()

    this.times2 = setInterval(() => {
      this.getDataInit()
    }, 5000)
  },
  destroyed() {
    clearInterval(this.times)
    clearInterval(this.times2)
  },
  methods: {
    getDataInit() {
      getAction('/liveRoom/detail/' + this.id, {}, '/api').then(res => {
        this.liveRoomInfo = res.data
        if (res.data.status == 0) {
          this.liveCountdown2()
        } else if (res.data.status == 1) {
          this.liveOngoing2()
        } else {
          clearInterval(this.times2)
        }
      })
    },
    renderTop() {
      const data = [
        {
          name: '在线',
          value: this.dataObj.onlineNum
        },
        {
          name: '观看',
          value: this.dataObj.viewNum
        },
        {
          name: '点击次数',
          value: this.dataObj.clickNum
        },
        {
          name: '点击人数',
          value: this.dataObj.userClickNum
        },
        {
          name: '付款成功',
          value: this.dataObj.userPayNum
        }
      ]
      return (
        <div class="wrapper">
          <div class="home-top">
            <div class="box">
              <div class="img_box">
                <img src={this.liveRoomInfo.faceImg} />
              </div>
              <div class="text_box">
                <span>{this.liveRoomInfo.name}</span>
                <span>
                  开播时间: {this.liveRoomInfo.startTime} -{' '}
                  {this.liveRoomInfo.endTime}
                </span>
              </div>
            </div>
            {data.map(e => {
              return (
                <div class="home-top-item">
                  <div>{e.value}</div>
                  <div>{e.name}</div>
                </div>
              )
            })}
          </div>
          <div class="home-btm">
            <div class="text-box">
              {this.liveRoomInfo.name && this.handleLiveStatus()}
            </div>
            <div class="btn-box">
              {/**
                   * this.liveRoomInfo.status == '0' && (
                <Button class="btn-1" onClick={this.statrLive}>
                  开始直播
                </Button>
              )
                   */}

              {this.liveRoomInfo.status == '1' && (
                <div>
                  <Button class="btn-2" onClick={this.endLive}>
                    直播结束
                  </Button>
                  {this.liveRoomInfo.streamStatus == '1' && (
                    <Button class="btn-3" onClick={this.statrEndLive}>
                      暂停直播
                    </Button>
                  )}
                  {this.liveRoomInfo.streamStatus == '2' && (
                    <Button class="btn-3" onClick={this.statrLiveEnd}>
                      恢复直播
                    </Button>
                  )}
                </div>
              )}

              {/**
                  <Button class="btn-3" onClick={() => this.pushUrl()}>
                推流地址
              </Button> */}

              <div class="text_box1">
                <span>推流状态</span>
                <span>
                  {
                    { '0': '未检测到推流', '1': '推流正常', '2': '失效' }[
                      this.liveRoomInfo.streamStatus
                    ]
                  }
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    },
    statrLive() {
      console.log('statr')
      //   postAction(
      //     '/liveRoom/update',
      //     {
      //       status: '1'
      //     },
      //     '/api'
      //   ).then(res => {
      //     if (res.code == 200) {
      //       this.$message.success('开始直播！')
      //       this.getDataInit()
      //     }
      //   })
    },
    endLive() {
      console.log('end')
      postAction('/liveRoom/endLive?id=' + this.id, {}, '/api').then(res => {
        if (res.code == 200) {
          this.$message.success('直播结束！')
          this.getDataInit()
        }
      })
    },
    statrEndLive() {
      console.log('statrEndLive')
      postAction('/liveRoom/dropLiveStream?id=' + this.id, {}, '/api').then(
        res => {
          if (res.code == 200) {
            this.$message.success('直播暂停！')
            this.getDataInit()
          }
        }
      )
    },
    statrLiveEnd() {
      console.log('statrLiveEnd')
      postAction('/liveRoom/resumeLiveStream?id=' + this.id, {}, '/api').then(
        res => {
          if (res.code == 200) {
            this.$message.success('直播恢复！')
            this.getDataInit()
          }
        }
      )
    },
    handleLiveStatus() {
      const data = [
        {
          display: this.liveRoomInfo.status == '2',
          value: this.liveRoomInfo.realEndTime,
          name: '直播已结束'
        },
        {
          display: this.liveRoomInfo.status == '1',
          value: this.liveCountdownText,
          name: '直播中'
        },
        {
          display: this.liveRoomInfo.status == '0',
          value: this.liveCountdownText,
          name: '倒计时'
        }
      ].filter(e => e.display)
      const select = data[0]
      return (
        <span>
          {select.name}:{select.value}
        </span>
      )
    },
    /**
     * startTime 传入的日期时间
     * timing 是否计时
     *        是：计时器
     *        否：倒计时
     */
    handleTimeDiff({ startTime, timing = true }) {
      let m1 = moment()
      let m2 = moment(startTime)
      let du = null
      if (timing) {
        du = moment.duration(m1 - m2, 'ms') // 是：计时器
      } else {
        du = moment.duration(m2 - m1, 'ms') // 否：倒计时
      }
      let dd = du.get('days')
      let hh = du.get('hours')
      let mm = du.get('minutes')
      let ss = du.get('seconds')
      if (dd) {
        return `${this.PrefixInteger(dd, 2)}天${this.PrefixInteger(
          hh,
          2
        )}时${this.PrefixInteger(mm, 2)}分${this.PrefixInteger(ss, 2)}秒`
      } else {
        return `${this.PrefixInteger(hh, 2)}时${this.PrefixInteger(
          mm,
          2
        )}分${this.PrefixInteger(ss, 2)}秒`
      }
    },
    liveOngoing2() {
      let that = this
      this.times = setInterval(() => {
        that.liveOngoing()
      }, 1000)
    },
    liveOngoing() {
      this.liveCountdownText = this.handleTimeDiff({
        startTime: this.liveRoomInfo.realStartTime,
        timing: true
      })
    },
    liveCountdown2() {
      let that = this
      this.times = setInterval(() => {
        that.liveCountdown()
        if (this.liveRoomInfo.status == '2') {
          clearInterval(this.times)
        }
      }, 1000)
    },
    liveCountdown() {
      let startDate = moment(this.liveRoomInfo.startTime).valueOf()
      let nowDate = moment().valueOf()
      if (nowDate - startDate < 0) {
        this.liveCountdownText = this.handleTimeDiff({
          startTime: this.liveRoomInfo.startTime,
          timing: false
        })
      } else {
        clearInterval(this.times)
        this.liveCountdownText = '00时00分00秒'
      }
    },
    PrefixInteger(num, n) {
      return (Array(n).join(0) + num).slice(-n)
    },
    pushUrl() {
      apiTool.showDrawer({
        title: '推流信息',
        width: '680px',
        view: PushInfo,
        viewProps: {},
        success: dataSource => {
          const { data, setHidden } = dataSource
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close
            }
          ]
        }
      })
    },
    handleChange(activeKey) {
      this.activeKey = activeKey
    },
    renderTabs() {
      return (
        <div class="tabs_box">
          <Tabs onChange={activeKey => this.handleChange(activeKey)}>
            <Tabs.TabPane tab="商品上架" key="1">
              {this.activeKey == '1' && (
                <GoodsShelves roomId={this.$route.query.id} />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="实时数据" key="2">
              {this.activeKey == '2' && (
                <RealTime roomId={this.$route.query.id} />
              )}
            </Tabs.TabPane>
            {/**
                 *   <Tabs.TabPane tab="网络监控" key="3">
              <NetworkMonito />
            </Tabs.TabPane>
                 */}
          </Tabs>
        </div>
      )
    }
  },
  render() {
    return (
      <div>
        {this.renderTop()}
        {this.renderTabs()}
      </div>
    )
  }
}
</script>

<style lang="less" scoped>
.tabs_box {
  /deep/.ant-tabs-tab {
    font-family: 'PingFang SC Normal', 'PingFang SC';
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    color: #515a6e;
    padding: 12px 0px;
  }
  /deep/.ant-tabs-ink-bar {
    height: 3px;
    background-color: #515a6e;
  }
}

.wrapper {
  height: 249px;
  width: 100%;
  background: white;
  margin: 10px 0;
  padding: 0 24px;
  box-sizing: border-box;
  border-radius: 5px;

  .home-top {
    width: 100%;
    height: 124px;
    border-bottom: 1px solid rgb(229, 231, 233);
    display: flex;
    align-items: center;
    .box {
      display: flex;
      align-items: center;
      width: 55%;

      .text_box {
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
          font-family: 'PingFang SC Normal', 'PingFang SC';
          font-weight: 500;
          font-style: normal;
          color: #333;
          &:nth-of-type(1) {
            font-size: 28px;
          }
          &:nth-of-type(2) {
            font-size: 14px;
          }
        }
      }
      .img_box {
        width: 133px;
        margin-right: 10px;

        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .home-btm {
    width: 100%;
    height: 124px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text-box {
      width: 55%;
      font-family: 'PingFang SC Normal', 'PingFang SC';
      font-weight: 500;
      font-style: normal;
      color: #333;
      font-size: 28px;
    }

    .btn-box {
      //   width: 20%;
      display: flex;
      align-items: center;

      .text_box1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 20px;

        span {
          font-family: 'PingFang SC Normal', 'PingFang SC';
          font-weight: 500;
          font-style: normal;
          color: #333;

          &:nth-of-type(2) {
            font-size: 20px;
          }
          &:nth-of-type(1) {
            font-size: 14px;
          }
        }
      }

      .btn-1 {
        width: 156px;
        height: 32px;
        background-color: rgb(44, 140, 240);
        border-radius: 4px;
        font-family: 'PingFang SC Normal', 'PingFang SC';
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: rgb(255, 255, 255);
        margin-right: 5px;
      }
      .btn-2 {
        width: 156px;
        height: 32px;
        background-color: rgb(163, 0, 20);
        border-radius: 4px;
        font-family: 'PingFang SC Normal', 'PingFang SC';
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: rgb(255, 255, 255);
        margin-right: 5px;
      }
      .btn-3 {
        width: 84px;
        height: 32px;
        background-color: rgb(255, 255, 255);
        border-radius: 4px;
        font-family: 'PingFang SC Normal', 'PingFang SC';
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: rgb(81, 90, 110);
        margin-right: 25px;
      }
    }
  }

  .home-top-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'PingFang SC Normal', 'PingFang SC';
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    color: #515a6e;
    border-left: 1px solid rgb(229, 231, 233);

    div {
      font-family: 'PingFang SC Normal', 'PingFang SC';
      font-weight: 500;
      font-style: normal;
      color: #333;
      &:nth-of-type(1) {
        font-size: 20px;
      }
      &:nth-of-type(2) {
        font-size: 14px;
      }
    }
  }
}
</style>
