// const apiTool = require('./apiTool')
import { Message } from 'ant-design-vue'
import apiTool from './apiTool'

export const getParams = function getParams(params = {}) {
  let text = ''
  Object.keys(JSON.parse(JSON.stringify(params))).forEach(e => {
    if (params[e] !== undefined && params[e] !== 'null') {
      text += e + '=' + params[e] + '&'
    }
  })
  if (text) {
    text = '?' + text
  }
  return text
}

export const getHeaders = function() {
  const token = localStorage.getItem('Access-Token').replace(/"/g, '')
  return {
    'Content-Type': 'application/json',
    VERSION: localStorage.getItem('VERSION') || undefined,
    appCode: localStorage.getItem('appCode') || undefined,
    Authorization: `bearer ${token}`,
    'X-Application-name': 'app'
  }
}

export const postAction = function(url, params = {}, host = '/liveoperation') {
  return new Promise((resolve, reject) => {
    fetch(host + url, {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(params),
      headers: getHeaders()
    })
      .then(e => {
        return new Promise(async resolve => {
          resolve({
            ...(await e.json()),
            status: e.status
          })
        })
      })
      .then(e => {
        if (e.status == 401) {
          apiTool.jumpLogin()
        } else if (e.code == 200) {
          resolve(e)
        } else {
          Message.error(e.msg)
          reject(e)
        }
      })
      .catch(e => {
        reject(e)
      })
  })
}

export const putAction = function(url, params = {}, host = '/api') {
  return new Promise((resolve, reject) => {
    fetch(host + url, {
      credentials: 'include',
      method: 'PUT',
      body: JSON.stringify(params),
      headers: getHeaders()
    })
      .then(e => {
        return new Promise(async resolve => {
          resolve({
            ...(await e.json()),
            status: e.status
          })
        })
      })
      .then(e => {
        if (e.status == 401) {
          apiTool.jumpLogin()
        } else if (e.code == 200) {
          resolve(e)
        } else {
          Message.error(e.msg)
          reject(e)
        }
      })
      .catch(e => {
        reject(e)
      })
  })
}

export const getAction = function(url, params, host = '/api') {
  return new Promise((resolve, reject) => {
    fetch(host + url.replace('/api', '') + getParams(params), {
      credentials: 'include',
      method: 'GET',
      headers: getHeaders()
    })
      .then(e => {
        return new Promise(async resolve => {
          resolve({
            ...(await e.json()),
            status: e.status
          })
        })
      })
      .then(e => {
        if (e.status == 401) {
          apiTool.jumpLogin()
        } else if (e.code == 200) {
          resolve(e)
        } else {
          Message.error(e.msg)
          reject(e)
        }
      })
      .catch(e => {
        reject(e)
      })
  })
}

// 删除
export const deleteAction = function(url, params = {}, host = '/bpi') {
  return new Promise((resolve, reject) => {
    fetch(host + url, {
      credentials: 'include',
      method: 'DELETE',
      body: JSON.stringify(params),
      headers: getHeaders()
    })
      .then(e => {
        return new Promise(async resolve => {
          resolve({
            ...(await e.json()),
            status: e.status
          })
        })
      })
      .then(e => {
        if (e.status == 401) {
          apiTool.jumpLogin()
        } else if (e.code == 200) {
          resolve(e)
        } else {
          Message.error(e.msg)
          reject(e)
        }
      })
      .catch(e => {
        reject(e)
      })
  })
}
// 删除
export const delAction = function(url, params = {}, host = '/api') {
  return new Promise((resolve, reject) => {
    fetch(host + url, {
      credentials: 'include',
      method: 'DELETE',
      body: JSON.stringify(params),
      headers: getHeaders()
    })
      .then(e => {
        return new Promise(async resolve => {
          resolve({
            ...(await e.json()),
            status: e.status
          })
        })
      })
      .then(e => {
        if (e.status == 401) {
          apiTool.jumpLogin()
        } else if (e.code == 200) {
          resolve(e)
        } else {
          Message.error(e.msg)
          reject(e)
        }
      })
      .catch(e => {
        reject(e)
      })
  })
}

export default {
  postAction,
  getAction,
  putAction,
  deleteAction,
  delAction
}
