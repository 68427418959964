<script>
import { Timeline } from 'ant-design-vue'
export default {
  props: {
    typeData: {
      type: Array,
      default: () => []
    }
  },
  render() {
    return (
      <Timeline>
        {this.typeData.map(e => {
          return (
            <Timeline.Item>
              {e.name}
              <br />
              {e.value}
            </Timeline.Item>
          )
        })}
      </Timeline>
    )
  }
}
</script>
