<script>
import FormUpload from '../FormUpload'
export default {
  props: {
    typeData: {
      type: Array,
      default: () => []
    },
    getValue: {
      type: Function,
      default: null
    },
    setValue: {
      type: Function,
      default: null
    },
    formData: {
      type: Object,
      default: () => {}
    }
  },
  render() {
    return (
      <div class="icon-uploder">
        {this.typeData.map(e => {
          return (
            <div class="icon-uploader-item" style={{ marginRight: '10px' }}>
              <FormUpload
                item={e}
                styles={{
                  width: 102 * (e.maxLength || 1) + 'px'
                }}
                onChange={value => this.setValue(e.key, value)}
                value={this.getValue(e)}
              />
              {e.desc && <span domProps={{ innerHTML: e.desc }} />}
            </div>
          )
        })}
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.icon-uploder {
  display: flex;
}
.icon-uploader-item {
  display: flex;
  flex: 1;
  align-items: flex-start;
  & > span {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 10px;
    font-size: 10px;
  }
}
</style>
