<script>
import { Checkbox, Button } from 'ant-design-vue'
export default {
  props: {
    typeData: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: ''
    }
  },
  methods: {
    onCheckItem(item) {
      const arr = new Set(this.value)
      const find = this.typeData.find(e => e.type == 'all')
      if (arr.has(item.value)) {
        if (item.type == 'all') {
          this.$emit('change', [])
        } else {
          arr.delete(item.value)
          if (this.value.length == this.typeData.length) {
            if (find) {
              this.$emit(
                'change',
                Array.from(arr).filter(e => e !== find.value)
              )
            } else {
              this.$emit('change', Array.from(arr))
            }
          } else {
            this.$emit('change', Array.from(arr))
          }
        }
      } else {
        if (item.type == 'all') {
          this.$emit(
            'change',
            this.typeData.map(e => e.value)
          )
        } else {
          arr.add(item.value)
          if (
            Array.from(arr).length ==
            this.typeData.filter(e => e.type !== 'all').length
          ) {
            this.$emit(
              'change',
              this.typeData.map(e => e.value)
            )
          } else {
            this.$emit('change', Array.from(arr))
          }
        }
      }
    },
    renderButton(item) {
      return (
        <Button style={{ marginRight: '10px', marginTop: '3px' }}>
          <Checkbox value={item.value} onClick={() => this.onCheckItem(item)}>
            {item.name}
          </Checkbox>
        </Button>
      )
    }
  },
  render() {
    return (
      <Checkbox.Group
        disabled={this.mode == 'detail'}
        value={this.value}
        style={{ display: 'flex', height: '100%' }}
      >
        {(this.typeData || []).map(e => this.renderButton(e))}
      </Checkbox.Group>
    )
  }
}
</script>
