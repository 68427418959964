<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import PushInfo from './comp/PushInfo'
import apiTool from '@/command/apiTool'
import moment from 'moment'
export default {
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/liveRoom/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input'
        },
        {
          name: '开播时间',
          type: 'rangePicker',
          keys: ['startTime', 'endTime'],
          showTime: true
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'lt-100',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '名称',
          type: 'lt-100'
        },
        {
          dataIndex: 'startTime',
          title: '最近开播',
          type: 'lt-100',
          customRender: function(text, records) {
            return `${moment(records.startTime).format('YYYY-MM-DD HH:mm')}~${moment(records.endTime).format(
              'YYYY-MM-DD HH:mm'
            )}`
          },
          onExport: (text, records) => {
            return `${moment(records.startTime).format('YYYY-MM-DD HH:mm')}~${moment(records.endTime).format(
              'YYYY-MM-DD HH:mm'
            )}`
          }
        },
        {
          dataIndex: 'status',
          title: '状态',
          width: 80,
          type: 'badge',
          filters: [
            {
              text: '未开始',
              value: '0'
            },
            {
              text: '直播中',
              value: '1'
            },
            {
              text: '已结束',
              value: '2'
            }
          ],
          onExport: records => {
            return ['未开始', '直播中', '已结束'][records]
          },
          onFilter: (value, record) => record.status == value,
          render(data) {
            return {
              showDot: true,
              name: {
                '0': '未开始',
                '1': '直播中',
                '2': '已结束'
              }[data],
              color: {
                '0': '#18BE6B',
                '1': '#ED4014',
                '2': '#E6EBF1'
              }[data]
            }
          }
        },
        {
          dataIndex: '',
          title: '操作',
          type: 'buttonGroup',
          width: '15%',
          typeData: ({ records }) => {
            return [
              {
                name: '控制台',
                onClick: () => {
                  this.$router.push('/marketingCenter/consoleManage?id=' + records.id)
                }
              },
              //   {
              //     name: '推流地址',
              //     onClick: () => {
              //       this.pushUrl()
              //     }
              //   },
              {
                name: '编辑',
                onClick: () => this.$router.push('/marketingCenter/liveManageDetail?id=' + records.id)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.delPostArr.call(this, {
                    url: '/liveRoom/deleteBatch',
                    params: {
                      idList: [records.id]
                    }
                  })
                }
              }
            ]
          }
        }
      ]
    },
    pushUrl() {
      apiTool.showDrawer({
        title: '推流信息',
        width: '680px',
        view: PushInfo,
        viewProps: {},
        success: dataSource => {
          const { data, setHidden } = dataSource
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close
            }
          ]
        }
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/marketingCenter/liveManageDetail')
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPostArr.call(this, {
                  url: '/liveRoom/deleteBatch',
                  params: {
                    idList: idList
                  }
                })
              }
            }
          ]
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
<style scoped>
p {
  border-radius: 0%;
  border: 1px solid;
}
</style>
