<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '@/components/DrawerForm'
import { postAction, getAction } from '@/command/netTool'
import { create, editPost } from '@/api/command'
import Countdown from './Countdown'
import moment from 'moment'
export default {
  props: {
    roomId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ...api.command.getState(),
      dataId: '',
      specificationsDataList: [],
      storeData: []
    }
  },
  mounted() {
    if (this.roomId) {
      api.command.getList.call(this, {
        url: '/liveRoomProduct/page',
        paramsValue: {
          roomId: this.roomId
        },
        current: 1
      })
    }
  },
  methods: {
    getColumns() {
      let that = this
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'lt-100',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'sort',
          title: '序号',
          align: 'left',
          width: 100
        },
        {
          dataIndex: 'productName',
          title: '特产名称',
          align: 'left',
          width: 100,
          sorter: (a, b) => a.productName.length - b.productName.length,
          customRender: (text, records) => {
            return (
              <div class="product-order-item">
                <img src={records.productImg} />
                <div>{text.substring(0, 25)}</div>
              </div>
            )
          }
        },
        {
          dataIndex: 'hitCount',
          title: '点击次数',
          align: 'left',
          width: 100,
          customRender: (text, records) => {
            return `${text ? text : 0}`
          }
        },
        {
          dataIndex: 'userHitCount',
          title: '点击人数',
          align: 'left',
          width: 100,
          customRender: (text, records) => {
            return `${text ? text : 0}`
          }
        },
        {
          dataIndex: 'livePrice',
          title: '直播价格',
          align: 'left',
          width: 100,
          customRender: (text, records) => {
            return `￥${text ? text : 0}`
          }
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          width: 50,
          type: 'badge',
          filters: [
            {
              text: '上架',
              value: '0'
            },
            {
              text: '下架',
              value: '1'
            }
          ],
          onExport: records => {
            return ['下架', '上架'][records]
          },
          onFilter: (value, record) => record.upDown == value,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          width: '15%',
          typeData: ({ records }) => {
            return [
              {
                // name: that.setPutTime(records.pushTime) ? '产品推送' : <Countdown />,
                // disabled: !that.setPutTime(records.pushTime),
                // onClick: () => that.setPutTime(records.pushTime) && that.handlePropup(records),
                render: () => {
                  return (
                    <Countdown
                      pushTime={records.pushTime}
                      onChange={() => {
                        that.handlePropup(records)
                      }}
                    />
                  )
                }
              },
              {
                name: '编辑',
                onClick: () => {
                  this.onRowSelect(records)
                }
              },
              {
                name: records.upDown == 1 ? '上架' : '下架',
                onClick: () => {
                  api.command.editGET.call(this, {
                    url: `/liveRoomProduct/${
                      records.upDown == 1 ? 'up' : 'down'
                    }?id=${records.id}`
                  })
                }
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: `/liveRoomProduct?id=${records.id}`
                  })
                }
              }
            ]
          }
        }
      ]
    },
    setPutTime(nextTime) {
      let endTime = moment(nextTime).add('second', 10)
      let nowTime = moment().valueOf()
      if (endTime < nowTime) {
        return moment(nowTime).diff(endTime, 'seconds')
      }
    },
    handlePropup(data) {
      api.command.edit.call(this, {
        url: '/liveRoomProduct/push?id=' + data.id
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.onRowSelect()
          }
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPut.call(this, {
                  url: '/liveRoomProduct/deleteBatch',
                  params: { idList }
                })
              }
            },
            {
              name: '批量上架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.edit.call(this, {
                  url: '/liveRoomProduct/upBatch',
                  params: { idList }
                })
              }
            },
            {
              name: '批量下架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.edit.call(this, {
                  url: '/liveRoomProduct/downBatch',
                  params: { idList }
                })
              }
            }
          ]
        }
      ]
    },
    getDrawerForm(source, formData) {
      const form = [
        {
          title: '产品地址',
          form: [
            {
              name: 'URL',
              type: 'input',
              key: 'productUrl',
              rules: [
                {
                  required: true,
                  type: 'string'
                }
              ]
            },
            {
              name: '产品名称',
              type: 'input',
              key: 'productName',
              rules: [
                {
                  required: true,
                  type: 'string'
                }
              ]
            },
            {
              name: '产品副标题',
              type: 'input',
              key: 'productSubTitle',
              rules: [
                {
                  required: true,
                  type: 'string'
                }
              ]
            }
          ]
        },
        {
          title: '价格',
          form: [
            {
              name: '直播价格',
              type: 'inputNumber',
              key: 'livePrice',
              props: {
                placeholder: '请输入直播价格',
                addonAfter: '元',
                min: 0
              },
              rules: [
                {
                  required: true,
                  type: 'number'
                }
              ]
            }
          ]
        },
        {
          title: '其他',
          form: [
            {
              name: '优先排序',
              type: 'inputNumber',
              key: 'sort',
              props: {
                placeholder: '请输入0-100的数字',
                min: 0,
                max: 100
              }
            }
          ]
        },
        {
          title: '产品图片',
          form: [
            {
              type: 'upload',
              key: 'productImg'
            }
          ]
        }
      ]
      return form
    },
    onRowSelect(records = {}) {
      if (records.id) {
        this.dataId = records.id
        getAction('/api/liveRoomProduct/detail?id=' + records.id).then(
          result => {
            if (result.code == 200) {
              this.handleStatePopup(result.data)
            } else {
              this.$message.error(result.msg)
            }
          }
        )
      } else {
        this.dataId = null
        this.handleStatePopup()
      }
    },
    handleStatePopup(
      source = {
        livePrice: '',
        upDown: '0',
        sort: ''
      }
    ) {
      let that = this
      apiTool.showDrawer({
        title: '商品设置',
        width: '720px',
        view: DrawerForm,
        viewProps: {
          data: formData => this.getDrawerForm(source, formData),
          form: source,
          formProps: {
            layout: 'horizontal',
            cols: 24,
            labelCol: { span: 3 },
            wrapperCol: { span: 21 }
          }
        },
        success: ({ data, setHidden }) => {
          if (!(data.productUrl && data.productName && data.livePrice)) {
            this.$message.warning('请输入URL/产品名称/直播价格')
            return
          }
          api.command[!this.dataId ? 'create' : 'edit']
            .call(this, {
              url: '/liveRoomProduct',
              params: {
                ...data,
                id: this.dataId,
                roomId: this.roomId
              }
            })
            .then(setHidden)
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        onSelectRow={this.onRowSelect}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
<style lang="less" scoped>
// /deep/.template-pagination {
//   display: none;
// }
.product-order-item {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
</style>
