import { BasicLayout } from '@/layouts'
import { marketing } from '@/core/icons'

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}
export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: {
      title: '首页'
    },
    redirect: '/marketingCenter',
    children: [
      {
        path: '/marketingCenter',
        name: 'marketingCenter',
        redirect: '/marketingCenter/liveManage',
        component: RouteView,
        meta: {
          title: '直播中心',
          keepAlive: false,
          icon: marketing,
          permission: ['dashboard']
        },
        children: [
          {
            path: '/marketingCenter/liveManage',
            name: 'liveManage',
            component: () => import('@/views/marketingCenter/liveManage'),
            meta: {
              title: '直播',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/marketingCenter/anchor',
            name: 'anchor',
            component: () => import('@/views/marketingCenter/anchor'),
            meta: {
              title: '主播',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/marketingCenter/liveManageDetail',
            name: 'liveManageDetail',
            hidden: true,
            component: () =>
              import('@/views/marketingCenter/liveManage/detail'),
            meta: {
              title: '直播详情',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/marketingCenter/consoleManage',
            name: 'consoleManage',
            hidden: true,
            component: () =>
              import('@/views/marketingCenter/liveManage/consoleManage'),
            meta: {
              title: '控制台',
              keepAlive: false,
              permission: ['dashboard']
            }
          }
        ]
      }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: RouteView,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () =>
          import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () =>
          import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () =>
          import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },
  {
    path: '/404',
    component: () =>
      import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]
