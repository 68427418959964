<script>
import { Radio, Button } from 'ant-design-vue'
export default {
  props: {
    typeData: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    if (this.item.defaultValue !== undefined) {
      this.$emit('change', this.item.defaultValue)
    }
  },
  methods: {
    renderButton(item) {
      return (
        <Button
          style={{
            marginRight: '10px',
            marginTop: '3px',
            padding: '0px 5px',
            ...item.styles
          }}
        >
          <Radio value={item.value} disabled={item.disabled}>
            {item.name}
          </Radio>
        </Button>
      )
    }
  },
  render() {
    return (
      <Radio.Group
        disabled={this.mode == 'detail' || this.item.disabled}
        value={this.value}
        onChange={e => this.$emit('change', e.target.value)}
        style={{ display: 'flex', height: '100%' }}
      >
        {(this.typeData || []).map(e => this.renderButton(e))}
      </Radio.Group>
    )
  }
}
</script>
