<template>
  <div>
    <div @click="send" v-if="timeData == '产品推送'" class="send_box">{{ timeData }}</div>
    <div v-else class="send_box1">{{ timeData }}</div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: {
    pushTime: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      timeData: '产品推送'
    }
  },
  watch: {},
  mounted() {
    if (this.pushTime) {
      this.startTime(
        moment(this.pushTime)
          .add(10, 'seconds')
          .valueOf()
      )
    } else {
      this.timeData = '产品推送'
    }
  },
  methods: {
    send() {
      this.startTime(
        moment()
          .add(10, 'seconds')
          .valueOf()
      )
      this.$emit('change')
    },
    playTime(time) {
      let currentTime = 0
      let times = setInterval(() => {
        currentTime += 1
        this.timeData = time - currentTime + 's冷却'
        if (currentTime == time) {
          this.timeData = '产品推送'
          clearInterval(times)
        }
      }, 1000)
    },
    startTime(time) {
      let a = moment().valueOf()
      if (time > a) {
        let setSeconds = moment(time).diff(a, 'seconds')
        if (setSeconds > 0) {
          this.playTime(setSeconds)
        }
      } else {
        this.timeData = '产品推送'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.send_box {
  color: #1890ff;
  margin-right: 15px;
  cursor: pointer;
}
.send_box1 {
  color: #aaa;
  margin-right: 15px;
}
</style>
