<script>
import api from '@/command/api'
import DrawerForm from '@/components/DrawerForm'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import moment from 'moment'
import { getAction, postAction } from '@/command/netTool'
import { render } from 'nprogress'
export default {
  data() {
    return {
      ...api.command.getState(),
      displayArea: []
    }
  },
  mounted() {
    this.getDataInit()
  },
  methods: {
    getDataInit() {
      api.command.getList.call(this, {
        url: '/liveUser/page',
        current: 1
      })
    },
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input'
        },
        {
          name: '手机号',
          type: 'input',
          key: 'phone'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'lt-100',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '主播名称',
          type: 'lt-200',
          customRender: (text, records) => {
            return (
              <div class="product-order-item">
                <img alt=" " src={records.headImg} />
                <div>{text}</div>
              </div>
            )
          }
        },
        {
          dataIndex: 'phone',
          title: '手机号码',
          type: 'lt-200'
        },
        {
          dataIndex: 'status',
          title: '状态',
          width: 100,
          type: 'badge',
          filters: [
            {
              text: '有效',
              value: '0'
            },
            {
              text: '失效',
              value: '1'
            }
          ],
          onExport: records => {
            return ['有效', '失效'][records]
          },
          onFilter: (value, record) => record.status == value,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '有效' : '失效',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: '',
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: records.status == 0 ? '无效' : '有效',
                onClick: () => {
                  if (records.status == 0) {
                    getAction(`/liveUser/disable?id=${records.id}`).then(res => {
                      if (res.code == 200) {
                        this.$message.success('编辑成功！')
                        this.getDataInit()
                      } else {
                        this.$message.error(res.msg)
                      }
                    })
                  } else {
                    getAction(`/liveUser/enable?id=${records.id}`).then(res => {
                      if (res.code == 200) {
                        this.$message.success('编辑成功！')
                        this.getDataInit()
                      } else {
                        this.$message.error(res.msg)
                      }
                    })
                  }
                }
              },
              {
                name: '编辑',
                onClick: () => {
                  getAction(`/liveUser/detail/${records.id}`, {}).then(res => {
                    if (res.code == 200) {
                      this.onRowSelect(res.data)
                    }
                  })
                }
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.delPostArr.call(this, {
                    url: '/liveUser/deleteBatch',
                    params: {
                      idList: [records.id]
                    }
                  })
                }
              }
            ]
          }
        }
      ]
    },
    getDrawerForm({ records, formData }) {
      const formArr = [
        {
          title: '基本信息',
          form: [
            {
              name: '昵称',
              type: 'input',
              key: 'name',
              rules: [
                {
                  required: true,
                  type: 'string'
                }
              ]
            },
            {
              name: '手机号',
              type: 'input',
              key: 'phone',
              rules: [
                {
                  required: true
                }
              ]
            }
          ]
        },
        {
          title: '头像',
          form: [
            {
              type: 'upload',
              key: 'headImg'
            }
          ]
        }
      ]
      return formArr
    },
    onRowSelect(records) {
      apiTool.showDrawer({
        title: '主播信息',
        view: DrawerForm,
        width: '600px',
        viewProps: {
          form: { ...records },
          data: formData => this.getDrawerForm({ records, formData })
        },
        success: ({ data, setHidden }) => {
          if (!(data.name && data.phone)) {
            this.$message.error('请完善数据！')
            return
          }

          let lxfs = /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/
          if (!lxfs.test(data.phone)) {
            this.$message.warning('请输入正确的联系方式！')
            return
          }
          if (data.id) {
            postAction('/liveUser/edit', {
              ...data
            }).then(res => {
              if (res.code == 200) {
                this.$message.success('编辑成功！')
                this.getDataInit()
                setHidden()
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            postAction('/liveUser/add', {
              ...data
            }).then(res => {
              if (res.code == 200) {
                this.$message.success('添加成功！')
                this.getDataInit()
                setHidden()
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.onRowSelect()
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPostArr.call(this, {
                  url: '/liveUser/deleteBatch',
                  params: {
                    idList: idList
                  }
                })
              }
            }
          ]
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.product-order-item img {
  width: 25px;
  height: 25px;
  float: left;
  margin-right: 10px;
  border-radius: 4px;
  background-color: #eee;
}
</style>
