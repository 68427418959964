<script>
import { Upload } from 'ant-design-vue'
export default {
  props: ['value', 'item', 'mode', 'styles'],
  methods: {
    handlePreview() {},
    getImgUrl(list) {
      const arr = []
      const { url } = JSON.parse(localStorage.getItem('qiniu')) || {}
      list.forEach(e => {
        if (e.status == 'done') {
          if (e.hash) {
            arr.push(url + e.hash + '?imageView2/0/format/jpg/q/75')
          } else if (e.url) {
            arr.push(e.url)
          } else {
            if (e.response && e.response.hash) {
              arr.push(url + e.response.hash + '?imageView2/0/format/jpg/q/75')
            }
          }
        }
      })
      return arr.join(',')
    },
    getFileList(url) {
      if (Array.isArray(url)) return url
      if (!url) return []
      return url
        .split(',')
        .filter(e => e)
        .map(e => {
          if (!e) return
          const name = e.split('?')[0].split('/')[3]
          return {
            uid: 'vc-upload-' + name,
            name,
            status: 'done',
            url: e,
            thumbUrl: e
          }
        })
    },
    handleChange(data) {
      //   console.log('data.fileList', data.fileList)
      if (data.file.status == 'uploading') {
        this.$emit('change', data.fileList)
      } else {
        this.$emit('change', this.getImgUrl(data.fileList))
      }
    },
    renderButton() {
      return (
        <div>
          <a-icon type="plus" />
          <div class="ant-upload-text">上传</div>
        </div>
      )
    },
    renderUploadItem(file) {
      if (file.indexOf('mp4') !== -1) {
        return <video src={file} />
      }
      return <img src={file} />
    }
  },
  /**
   *    {(this.getFileList(this.value) || []).length <
          (this.item.maxLength || 1) && this.renderButton()}
   */
  render() {
    const { token } = JSON.parse(localStorage.getItem('qiniu')) || {}
    return (
      <Upload.Dragger
        name="file"
        // headers={{
        //   token,
        //   uploadToken: token
        // }}
        action="http://upload.qiniup.com/"
        list-type="picture-card"
        fileList={this.getFileList(this.value)}
        // accept={'text/img'}
        // disabled={this.mode == 'detail'}
        // class="uploader"
        data={{ token }}
        // onPreview={this.handlePreview}
        onChange={this.handleChange}
        style={this.styles}
        renderUploadItem={this.renderUploadItem}
        multiple={true}
      >
        <div>点击上传</div>
      </Upload.Dragger>
    )
  }
}
</script>
<style lang="less" scoped>
// .uploader {
//   margin-left: 0px !important;
// }
</style>
