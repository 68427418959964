<script>
import { Input } from 'ant-design-vue'
export default {
  props: ['value', 'mode', 'item'],
  render() {
    return (
      <Input.TextArea
        disabled={this.mode == 'detail'}
        value={this.value}
        style={{ minHeight: '200px' }}
        placeholder={
          this.item.placeholder
            ? this.item.placeholder
            : '请输入' + (this.item.name || this.item.label)
        }
        onChange={e => this.$emit('change', e.target.value)}
      />
    )
  }
}
</script>
