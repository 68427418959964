import api from '@/command/api'
import { Message } from 'ant-design-vue'
import request from '@/utils/request'
/**
 * 批量删除
 */
export const delBatch = function delBatch({ url, params = {} } = {}) {
  request({
    url,
    method: 'DELETE',
    params
  }).then(e => {
    if (e.code == 200) {
      Message.success('删除成功')
      api.command.getList.call(this)
    } else {
      Message.error(e.message)
    }
  })
}
export default {
  delBatch
}
