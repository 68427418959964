<script>
import { Radio } from 'ant-design-vue'
export default {
  props: ['typeData', 'value', 'mode'],
  render() {
    return (
      <Radio.Group
        disabled={this.mode == 'detail'}
        value={this.value}
        onChange={e => this.$emit('change', e.target.value)}
        options={(this.typeData || []).map(e => ({
          label: e.name,
          value: e.value
        }))}
      ></Radio.Group>
    )
  }
}
</script>
