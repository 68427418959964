<script>
import { quillEditor, Quill } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
  VideoExtend,
  QuillVideoWatch,
  container
} from 'quill-video-image-module'
import video from 'quill-video-image-module/video'
import { message } from 'ant-design-vue'
Quill.register(video, true)
Quill.register('modules/VideoExtend', VideoExtend)

const toolbarOpation = [
  ['style', ['style']],
  ['font', ['bold', 'underline', 'clear']],
  ['color', ['color']],
  ['para', ['ul', 'ol', 'paragraph']],
  ['table', ['table']],
  ['insert', ['link', 'picture', 'video']]
  // ['view', ['fullscreen', 'codeview', 'help']]
]

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'edit'
    }
  },
  watch: {
    value(newValue) {
      if (!this.init && newValue) {
        this.init = true
        $('#summernote').summernote('reset')
        $('#summernote').summernote('code', newValue)
      }
    }
  },
  mounted() {
    let that = this
    if (this.value) {
      $('#summernote').summernote('code', this.value)
    }
    $('#summernote').summernote({
      placeholder: '请输入内容',
      tabsize: 2,
      height: '600',
      lang: 'zh-CN',
      toolbar: toolbarOpation,
      callbacks: {
        onImageUpload: function(files) {
          that.updateImage(files[0], (url, name) => {
            $('#summernote').summernote('insertImage', url, name)
          })
        }
      }
    })
    $('#summernote').on('summernote.change', function(we, contents, $editable) {
      that.$emit('change', contents)
    })
  },
  methods: {
    updateImage(file, success) {
      const form = new FormData()
      const token = localStorage.getItem('Access-Token').replace(/"/g, '')
      form.append('multipartFile', file)
      message.warning('图片上传中')
      fetch('/api/common/upload', {
        method: 'POST',
        body: form,
        headers: {
          'X-Application-name': 'app',
          Authorization: `bearer ${token}`
        }
      })
        .then(e => e.json())
        .then(e => {
          message.success('图片上传成功')
          success && success(e.fileUrl, e.name)
        })
        .catch(() => {
          message.error('图片上传失败')
        })
    },
    getOption() {
      let that = this
      return {
        modules: {
          VideoExtend: {
            loading: true,
            name: 'multipartFile',
            size: 100, // 可选参数 视频大小，单位为M，1M = 1024kb
            action: '/api/common/upload', // 视频上传接口
            headers: xhr => {
              return {
                'X-Application-name': 'app',
                Authorization: `bearer ${localStorage.getItem('Access-Token')}`
              }
            },
            response: res => {
              return res.fileUrl + '.mp4'
            },
            sizeError: () => {
              alert('视频不能大于100M')
            }
          },
          toolbar: {
            container: container,
            handlers: {
              video: function name() {
                QuillVideoWatch.emit(this.quill.id)
              },
              image: function(value) {
                if (value) {
                  that.updateImage()
                } else {
                  this.quill.format('image', false)
                }
              }
            }
          }
        }
      }
    },
    onChange(data) {
      this.$emit('change', data.html)
    }
  },
  render() {
    return <div id="summernote" />
  }
}
</script>
<style lang="less" scoped>
.form-rich {
  height: 600px;
  /deep/.ql-toolbar.ql-snow {
    border: none !important;
    border-bottom: 1px solid #ccc !important;
  }
  /deep/.ql-container.ql-snow {
    border: none !important;
    border-bottom: 1px solid #ccc !important;
  }
  /deep/.ql-picker-label {
    display: flex;
    align-items: center;
  }
}
</style>
