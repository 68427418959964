<script>
const DataSet = require('@antv/data-set')
export default {
  props: {
    charData: {
      type: Array,
      default: function() {
        return {
          data: []
        }
      }
    },
    id: String
  },
  data() {
    return {
      chart: null
    }
  },
  // 如果使用serverData传过来的静态数据 请使用mounted()方法 并注释掉watch
  mounted() {
    this.drawChart(this.charData)
  },
  // 监听API接口传过来的数据 使用watch
  watch: {
    charData: function(val, oldVal) {
      // 监听charData，当发生变化时，触发这个回调函数绘制图表
      //   console.log('new: %s, old: %s', val, oldVal)
      this.chart.clear()
      this.chart.changeData(val)
      this.drawChart(val)
    }
  },
  methods: {
    drawChart(newData = this.charData) {
      let ds = new DataSet()
      let dv = ds.createView().source(newData)
      // fold 方式完成了行列转换，如果不想使用 DataSet 直接手工转换数据即可
      dv.transform({
        type: 'fold',
        fields: ['count'],
        value: 'temperature'
      })
      this.chart = new G2.Chart({
        container: this.id,
        forceFit: true,
        height: 400
      })
      this.chart.source(dv)
      this.chart
        .line()
        .position('dateStr*temperature')
        .color('rgb(45, 140, 240)')
        .shape('smooth')
      this.chart.render()
    }
  },
  render() {
    return <div id={this.id} class="gcharts"></div>
  }
}
</script>
<style lang="less" scope>
.gcharts {
  width: 100%;
  height: 100%;
}
</style>
